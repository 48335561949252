import React from "react";
import '../App.css';
import { Link } from "react-router-dom";




class Sidebar2 extends React.Component {
  // scrollToSection = (sectionId) => {
  //   const section = document.getElementById(sectionId);
  //   if (section) {
  //     section.scrollIntoView({ behavior: "smooth" });
  //     document.querySelector(".sidebar").classList.remove("open");
  //   }
  // };
  scrollToSection = (sectionId) => {
    document.querySelector(".sidebar").classList.remove("open");
    const section = document.getElementById(sectionId);
    if (section) {
      const topPosition = section.offsetTop -55; // Ajusta el espacio desde la parte superior
      window.scrollTo({
        top: topPosition,
        behavior: "smooth",
      });
    }
  };
  logout = () => {
    // Lógica para cerrar sesión, como limpiar el localStorage, redireccionar, etc.
    // Ejemplo básico:
    localStorage.clear();
    window.location.href = "/"; // Redirige a la página de inicio
  };

  render() {
    const closeMenu = () => {
      document.querySelector(".sidebar").classList.remove("open");
    };

    return (
      <div className="lista">
        <Link
          // onClick={closeMenu}
          // to="/"
          // id="home"
          // className="lista-sidebar"
          onClick={() => this.scrollToSection("inicio")}
          className="lista-sidebar"
          to="/#inicio"
        >
          Inicio
        </Link>
        {/* <Link  onClick={closeMenu} to="/mbokaja-juegos" id="Juegos" className="lista-sidebar">Juegos</Link>
          <Link  onClick={closeMenu} to="/noticias-y-trucos" id="noticias-y-trucos" className="lista-sidebar">Noticias y trucos</Link>
          <Link  onClick={closeMenu} to="/promociones" id="Promociones" className="lista-sidebar">Promociones</Link>
          <Link  onClick={closeMenu} to="/tutoriales" id="Tutoriales" className="lista-sidebar">Tutoriales</Link>
          <Link  onClick={closeMenu} to="/sorteos"id="Sorteos" className="lista-sidebar">Sorteos</Link> */}
        <Link
          onClick={closeMenu}
          to="/canjea-tus-puntos"
          id="canjear"
          className="lista-sidebar"
        >
          Productos
        </Link>
        <Link
          // onClick={closeMenu}
          // to="/#contacto-nav"
          // id="canjear"
          // className="lista-sidebar"
          onClick={() => this.scrollToSection("beneficios")}
          className="lista-sidebar"
          to="/#beneficios"
        >
          Beneficios
        </Link>
        <Link
          // onClick={closeMenu}
          // className="lista-sidebar"
          // to="/#preguntasFrecuentes"
          onClick={() => this.scrollToSection("preguntasFrecuentes")}
          className="lista-sidebar"
          to="/#preguntasFrecuentes"
        >
          {" "}
          Preguntas frecuentes
        </Link>
        <Link
          // to="/#juegoResponsable"
          // onClick={closeMenu}
          onClick={() => this.scrollToSection("juegoResponsable")}
          className="lista-sidebar"
          to="/#juegoResponsable"
        >
          Juego responsable
        </Link>
        <Link
          // onClick={closeMenu}
          // to="/#contacto-nav"
          // id="Contacto"
          // className="lista-sidebar"
          onClick={() => this.scrollToSection("contactos")}
          className="lista-sidebar"
          to="/#contactos"
        >
          Contacto
        </Link>
        {localStorage.userInfo ? (
          <>
            <Link
              onClick={closeMenu}
              to="/pedidos"
              id="Profile"
              className="lista-sidebar"
            >
              Mis Pedidos
            </Link>
            <Link
              onClick={closeMenu}
              to="/mi-cuenta"
              id="Profile"
              className="lista-sidebar"
            >
              Perfil
            </Link>
            <button onClick={this.logout} className="cerrar-sesion-btn">
              Cerrar Sesión
            </button>
          </>
        ) : (
          <Link
            onClick={closeMenu}
            to="/signin"
            id="Login"
            className="lista-sidebar"
          >
            Login
          </Link>
        )}

        {/* <Link  onClick={closeMenu} to="/socios" id="Socios" className="lista-sidebar">Socios</Link> */}
      </div>
    );
  }
}
  export default Sidebar2;